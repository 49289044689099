<template>
  <!-- =======================
Main banner START -->
  <section class="pt-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- Card item START -->
          <div
            class="card overflow-hidden h-100px h-xl-200px rounded-3"
            style="
              background-image: url(/assets/images/bg/autoevaluacion-via-verde.jpg);
              background-position: center left;
              background-size: cover;
            "
          >
            <!-- Background dark overlay -->
            <div class="bg-overlay bg-dark opacity-6"></div>

            <!-- Card image overlay -->
            <div class="card-img-overlay d-flex align-items-start flex-column">
              <!-- Card overlay Top -->
              <!-- <div class="w-100 mb-auto d-flex justify-content-end">
							<button class="btn btn-sm btn-white mb-0"><i class="bi bi-share"></i> Share</button>
						</div> -->
              <!-- Card overlay bottom -->
              <div class="w-100 mt-auto">
                <div class="row p-0 p-sm-3">
                  <div class="col-11 col-lg-7">
                    <!-- Title -->
                    <!-- <h1 class="text-white">¿Preparado para exportar? responde al Diagnóstico</h1>
									<p class="text-white mb-0">Una forma efectiva de ver que capacidades falta desarrollar es respondiendo al Diagnóstico.</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card item END -->
        </div>
      </div>
    </div>
  </section>
  <!-- =======================
Main banner END -->
  <!-- =======================
Page content START -->
  <section class="pt-0">
    <div class="container">
      <div class="row">
        <!--ALERT DE INFORMACION-->
        <div class="col-md-12">
          <div class="alert alert-light" role="alert">
            <div v-if="routeName === 'showDiagnostics'">
              <div style="display: flex; align-items: center">
                <h1 class="" style="color: #00a052">
                  Bienvenido al Módulo de Autodiagnóstico de Sostenibilidad
                </h1>
                <h5 style="margin-left: 10px">(gratuito)</h5>
              </div>
              <p class="mb-0">
                Vía Verde es un Proyecto que impulsa a las mipymes de los
                sectores agrícola, forestal y pecuario del Departamente Santa
                Cruz fomentando su internacionalización al mercado de la Unión
                Europea a través del fortalecimiento de diversas capacidades,
                entre las que se tiene los criterios de Sostenibilidad.
              </p>
              <p class="mb-0 mt-4">
                <i
                  ><b>Importante:</b> Su información será tratada con
                  confidencialidad<br
                /></i>
              </p>
              <h2 class="alert-heading mt-4" style="color: #00a052">
                ¿Está listo para llevar a su empresa al siguiente nivel en los
                mercados internacionales?
              </h2>
              <p>
                Nuestra herramienta de Autodiagnóstico de Sostenibilidad está
                diseñada para ayudarle a adaptar sus operaciones a los rigurosos
                requerimientos de mercados altamente competitivos, como el de la
                Unión Europea.
              </p>
              <h2 class="alert-heading mt-4" style="color: #00a052">
                ¿Por qué realizar un Autodiagnóstico de Sostenibilidad?
              </h2>
              <ul>
                <li>
                  <p>
                    <b>Oportunidades de mejora:</b> Obtenga un resultado claro
                    sobre su situación actual, permitiéndole identificar áreas
                    de mejora.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Informe personalizado:</b> Al finalizar el
                    Autodiagnóstico de Sostenibilidad, recibirá un informe
                    automático categorizado para que descubra cómo puede
                    alinearse mejor con los criterios de sostenibilidad.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Preparación para el éxito:</b> Responder de manera clara
                    y veraz garantizará la precisión de su diagnóstico,
                    proporcionando una base sólida para su estrategia de
                    sostenibilidad.
                  </p>
                </li>
              </ul>
              <h2 class="alert-heading mt-4" style="color: #00a052">
                Nos enfocamos en la mejora continua
              </h2>

              <p>
                Después de completar nuestras capacitaciones, se habilitará la
                opción para realizar un nuevo Autodiagnóstico de Sostenibilidad.
                Esto le permitirá medir su progreso y las mejoras alcanzadas en
                diversos aspectos de sostenibilidad.
              </p>

              <p class="mb-0">
                No pierda esta oportunidad de evaluar y mejorar sus prácticas
                empresariales. Comience ahora el Autodiagnóstico de
                Sostenibilidad y dé el primer paso hacia una operación más
                sostenible y competitiva en el mercado global.
              </p>
              <h3 class="alert-heading mt-4" style="color: #00a052">
                ¡Realice el Autodiagnóstico de Sostenibilidad de forma gratuita
                ya mismo!
              </h3>

              <h2 class="alert-heading mt-4" style="color: #00a052">
                Glosario de Sostenibilidad
              </h2>
              <p>
                Revise el Glosario de Términos Sostenibilidad para poder
                realizar un mejor autodiagnóstico.
              </p>
              <button
                type="button"
                class="btn btn-warning mt-3 mt-md-0"
                @click="downloadGlossary"
              >
                <span
                  v-if="isDownloading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-else>
                  Descargar Glosario de Términos de Sostenibilidad
                  <i class="fas fa-file-download"></i>
                </span>
              </button>
            </div>
            <div v-if="routeName === 'questions'">
              <h2 class="">Bienvenido al Autodiagnóstico de Sostenibilidad</h2>
              <p class="mb-0">
                Nos complace que haya decidido participar en nuestro
                Autodiagnóstico de Sostenibilidad, una herramienta esencial para
                adaptar las operaciones de su empresa a los exigentes estándares
                del mercado internacional, especialmente el de la Unión Europea
              </p>
              <h4 class="alert-heading mt-4">
                Antes de comenzar, tenga en cuenta lo siguiente:
              </h4>
              <ul>
                <li>
                  <p>
                    <b>Responda con claridad y veracidad:</b> Para obtener
                    resultados precisos y útiles, es fundamental que responda
                    cada pregunta de manera clara y honesta. Su sinceridad
                    permitirá que el informe final refleje de manera fiel la
                    situación actual de su empresa
                  </p>
                </li>
                <li>
                  <p>
                    <b>Utilice el Glosario:</b> Si en algún momento tiene dudas
                    sobre algún término o concepto, no dude en consultar el
                    glosario de sostenibilidad que hemos proporcionado
                  </p>
                </li>
                <li>
                  <p>
                    <b>Importante:</b> Este Autodiagnóstico de Sostenibilidad
                    solo puede realizarse una vez. Aproveche esta oportunidad
                    única para evaluar sus prácticas y descubrir cómo puede
                    mejorar
                  </p>
                </li>
                <li>
                  <p>
                    <b>Ayuda en todo momento:</b> A lo largo del cuestionario,
                    encontrará opciones de información diseñadas para ayudarle a
                    responder de la mejor manera posible
                  </p>
                </li>
              </ul>
              <h4 class="alert-heading mt-4">
                ¡Estamos aquí para apoyarle en su camino hacia una operación más
                sostenible y competitiva!
              </h4>
              <p>
                Le agradecemos su participación y su compromiso con la
                sostenibilidad. ¡Buena suerte!
              </p>
            </div>
            <hr />
          </div>
        </div>

        <router-view></router-view>
      </div>
      <!-- Row END -->
    </div>
  </section>
  <!-- =======================
Page content END -->
</template>

<script>
import { computed, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useDiagnosticStore } from "@/modules/diagnostic/stores/diagnostic";
import { useLoginStore } from "@/modules/auth/stores/login";
import { useToast } from "vue-toastification";
import { POSITION } from "vue-toastification";
import Swal from "sweetalert2";
export default {
  setup() {
    const route = useRoute();
    const routeName = computed(() => route.name);
    const diagnosticStore = useDiagnosticStore();
    const loginStore = useLoginStore();
    const isDownloading = ref(false);
    const isAuthenticated = loginStore.isAuthenticated;

    const downloadGlossary = async () => {
      if (isAuthenticated) {
        isDownloading.value = true;
        const toast = useToast();
        // Si el usuario está autenticado, proceder con la descarga
        await diagnosticStore.downloadGlossary();
        if (diagnosticStore.error) {
          toast.error(diagnosticStore.error);
        }
        isDownloading.value = false;
      } else {
        // Si el usuario no está autenticado, mostrar el mensaje de SweetAlert
        await Swal.fire({
          title: "¿Desea visualizar el Glosario de Términos de Sostenibilidad?",
          text: "Le invitamos registrarse si aún no tiene una Cuenta de Usuario, o inicie sesión para acceder al contenido disponible",
          confirmButtonText: "Entendido",
          confirmButtonColor: "#28a745",
        });
      }
    };

    const showErrorValidationToast = (message) => {
      const toast = useToast();
      toast.error(message, {
        position: POSITION.TOP_CENTER,
      });
    };

    watch(
      () => diagnosticStore.error,
      (newError) => {
        if (newError) {
          showErrorValidationToast(newError);
        }
      }
    );

    return {
      routeName,
      downloadGlossary,
      isDownloading,
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
  font-size: 28px;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,
h4,
h5 {
  font-family: "Merriweather" !important;
}
p,
span,
li,
a,
button {
  font-family: "Montserrat" !important;
}
p,
a,
span,
li {
  font-size: 18px;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.8rem;
}
/* Asegura que el contenedor se ajuste al contenido */
.alert {
  overflow: hidden; /* Evita que el contenido se salga */
  padding: 20px; /* Ajusta el espacio interno */
}

/* Alinear correctamente el texto y el "(gratuito)" */
.alert h1 {
  display: inline-block;
  font-size: 28px;
  font-weight: bold;
}

.alert h5 {
  display: inline-block;
  font-size: 18px;
  margin-left: 10px; /* Ajusta el espacio entre el título y "(gratuito)" */
  font-weight: normal;
  vertical-align: middle; /* Asegura que esté alineado correctamente con el título */
  font-weight: bold;
}

/* Ajustar el botón para que no sobresalga */
.btn {
  max-width: 100%; /* Asegura que el botón no exceda el ancho del contenedor */
  white-space: normal; /* Permite que el texto del botón se divida en varias líneas si es necesario */
  word-wrap: break-word; /* Evita que el contenido del botón se desborde */
}
</style>
