export default {
  children: [
    {
      path: "",
      name: "guia-search",
      component: () => import("@/modules/guia/components/GuiaSearch.vue"),
    },
    {
      path: "descargar",
      name: "guia-download",
      component: () => import("@/modules/guia/components/GuiaDownload.vue"),
    },
  ],
};
