<template>


  <!-- =======================
Main banner START -->
<section class="pt-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- Card item START -->
          <div
            class="card overflow-hidden h-100px h-xl-200px rounded-3"
            style="
              background-image: url(/assets/images/bg/fondo-acercade-viaverde.jpg);
              background-position: center left;
              background-size: cover;
            "
          >
            <!-- Background dark overlay -->
            <div class="bg-overlay bg-dark opacity-6"></div>

            <!-- Card image overlay -->
            <div class="card-img-overlay d-flex align-items-start flex-column">
              <!-- Card overlay Top -->
              <!-- <div class="w-100 mb-auto d-flex justify-content-end">
							<button class="btn btn-sm btn-white mb-0"><i class="bi bi-share"></i> Share</button>
						</div> -->
              <!-- Card overlay bottom -->
              <div class="w-100 mt-auto">
                <div class="row p-0 p-sm-3">
                  <div class="col-11 col-lg-7">
                    <!-- Title -->
                    <!-- <h1 class="text-white">¿Preparado para exportar? responde al Diagnóstico</h1>
									<p class="text-white mb-0">Una forma efectiva de ver que capacidades falta desarrollar es respondiendo al Diagnóstico.</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card item END -->
        </div>
      </div>
    </div>
  </section>
  <!-- =======================
Main banner END -->

  <div class="container seccion-1">
    <div class="row">
      <div class="col-md-8 contenido">
        <h3 class="mb-4">SOLICITANTE</h3>

        <h2>Filiera Agrícola Italiana</h2>

        <p class="mt-4">
          Fue fundada en 1995, en Roma, por un gran grupo de operadores del
          sector agrícola a nivel nacional que sentían la necesidad de hacer una
          contribución eficaz y duradera a la agricultura y al mundo sindical
          que giraba en torno a ella. Promueven la inclusión de la agricultura
          en el circuito económico nacional, de manera que se garantice su
          constante desarrollo, utilizando herramientas adecuadas para dotar a
          la agricultura de recursos técnicos y profesionales para adaptarlos a
          las demandas del mercado.
        </p>
      </div>

      <div class="col-md-4 imagen">
        <img
          src="assets/images/logo-fagri.png"
          alt="Filiera Agrícola Italiana"
          title="Filiera Agrícola Italiana"
          class="img-fluid img-tablet img-responsive logo-fagri"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4 class="mt-4">Objetivos</h4>

        <p>
          Hasta la fecha, alrededor de 110.000 personas están registradas en
          F.AGR.I., entre agricultores, empresas agroalimentarias, empresas
          agroindustriales y jubilados.
        </p>

        <p>
          F.AGR.I. está preparada para satisfacer las necesidades de todos, con
          numerosos servicios hechos a medida para todos. Asimismo, promueven la
          inclusión de la agricultura en el circuito económico nacional, de
          manera que se garantice su constante desarrollo, utilizando
          herramientas adecuadas para dotar a la agricultura de recursos
          técnicos y profesionales para adaptarlos a las demandas del mercado.
        </p>

        <p>
          F.AGR.I. establece vínculos con todas las organizaciones de
          trabajadores, productores y consumidores que operan en Italia y en el
          extranjero con el objetivo de ampliar la actividad del mundo agrícola,
          promoviendo iniciativas encaminadas a coordinar las actividades
          agrícolas con las de artesanía, comercio, turismo y otras actividades
          del sector terciario. De esa manera fomentan un adecuado desarrollo
          del emprendimiento agrícola, especialmente entre los jóvenes (hombre y
          mujeres). Entre otras cosas, también promueven iniciativas coherentes
          con la necesidad de una política agrícola encaminada a combinar la
          ruralidad con una sólida política ambiental y territorial, con el fin
          de lograr mejores condiciones de calidad de vida.
        </p>
      </div>
    </div>
  </div>

  <div class="container seccion-1">
    <div class="row">
      <div class="col-md-8 contenido">
        <h3 class="mb-4">CO-SOLICITANTE</h3>

        <h2>Instituto Boliviano de Comercio Exterior (IBCE)</h2>

        <p class="mt-4">
          El Instituto Boliviano de Comercio Exterior (IBCE) es una institución
          técnica de promoción del comercio, cuyo trabajo se enmarca en el
          cumplimiento de los grandes objetivos nacionales de crecimiento
          económico y desarrollo social. El objetivo económico del IBCE es el de
          contribuir al desarrollo productivo del país; su objetivo social es
          generar crecientes fuentes de empleo a través de la consolidación del
          comercio exterior boliviano.
        </p>
      </div>

      <div class="col-md-4 imagen">
        <img
          src="assets/images/ibce-normal.jpg"
          alt="log IBCE"
          title="IBCE"
          class="img-fluid img-tablet img-responsive logo-ibce"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4 class="mt-1">Trayectoria</h4>

        <p>
          Lleva más de 38 años desarrollando diferentes tipos de acciones hacia
          el desarrollo económico y sectorial del país; donde se ha convertido
          en una institución referente del comercio boliviano, hoy en día con
          expertise en el desarrollo de estudios, promoción y desarrollador de
          TICs, promotor de temas coyunturales a través de foros y campañas
          comunicacionales. Y más de 18 años de experiencia trabajando en el
          área de la RSE, coadyuvando a las empresas bolivianas a mejorar su
          sistema de negocios aportando un valor intangible que permita
          gestionar la empresa para ser considerada como económicamente viable,
          ambientalmente amigable y socialmente responsable, desarrollando
          diagnósticos e indicadores de gestión, además de promover las acciones
          responsables en las empresas. <br /><br />
          La solidez, el prestigio y la trayectoria del IBCE a nivel nacional,
          le han permitido tener una “voz propia”, respetada por los distintos
          agentes económicos, tanto públicos como privados, siendo capaces de
          generar corrientes de opinión y posicionar temáticas que repercuten
          nacional e internacionalmente.
          <br /><br />
          Además, la institución es una referencia del comercio exterior
          boliviano, por ello, se cuenta con propias publicaciones -impresas y
          digitales- que proveen constantemente información completa y de
          carácter solicitado por los agentes económicos del país en particular
          y la sociedad civil en su conjunto. El IBCE genera y difunde
          información oficial mediante notas de prensa que se publican en los
          diferentes medios informativos a nivel nacional e internacional.
          <br /><br />
          En las más de tres décadas de vida de la institución, la credibilidad
          que ha alcanzado el IBCE con la entrega de información especializada
          ha provocado un crecimiento verdaderamente exponencial de su
          visibilización, alcanzando un “pico” en la gestión 2018 con más de
          34.000 apariciones de la institución por la prensa nacional e
          internacional, escrita y oral y vía Internet (monitoreo de noticias en
          medios de prensa orales y escritos, además de sitios web y blogs),
          atribuyéndose ello a la abundante producción y difusión de información
          especializada, así como a los análisis técnicos que realiza.
        </p>
      </div>
    </div>
  </div>

  <div class="container seccion-1 mb-6">
    <div class="row">
      <div class="col-md-8 contenido">
        <h3 class="mb-4">CO-SOLICITANTE</h3>

        <h2>Fundación para el Desarrollo (FUNDEFOREST)</h2>

        <p class="mt-4">
          La Fundación para el Desarrollo Forestal (FUNDEFOREST) es una entidad
          civil sin fines de lucro, fundada el 16 de agosto de 2005, bajo los
          lineamientos de la Cámara Forestal de Bolivia (CFB) , con el objetivo
          de promover el desarrollo forestal, coadyuvar a las acciones de
          conservación de los bosques, promoviendo su uso sostenible, su
          aprovechamiento integral, la capacitación técnica y académica de los
          productores forestales, así como la canalización de recursos para
          contribuir al desarrollo de la industria forestal y la prestación de
          servicios industriales en el país.
        </p>
      </div>

      <div class="col-md-4 imagen">
        <img
          src="assets/images/Logo-Fundeforest.png"
          alt="Fundación para el Desarrollo (FUNDEFOREST)"
          title="Fundación para el Desarrollo (FUNDEFOREST)"
          class="img-fluid img-tablet img-responsive logo-fundeforest"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4 class="">Principios fundamentales</h4>

        <p>Sus principios fundamentales son:</p>
        <ul>
          <li>Promover y coadyuvar a la conservación de los bosques</li>

          <li>Promover el uso sostenible e integral del bosque</li>

          <li>
            Captar y administrar recursos económicos que permitan, dentro del
            respeto a las leyes, contribuir a la capacitación técnica y
            académica de los productores forestales, así como la prestación de
            servicios industriales en el país
          </li>

          <li>
            Apoyar la ejecución de proyectos destinados a la conservación de la
            diversidad biológica en la región, con las organizaciones y
            entidades públicas, privadas, municipales, empresariales, campesinas
            e indígenas involucradas
          </li>

          <li>
            Promover el desarrollo de la industria forestal en todas sus fases;
            así como la renovación tecnológica
          </li>
        </ul>
      </div>
    </div>
  </div>
    <FloatingActionButton :categoryNames="categoryNames" />
</template>

<script>
import {ref } from "vue";
import FloatingActionButton from "@/modules/common/components/FloatingActionButton.vue";
export default {
  components: {
    FloatingActionButton,
  },
  setup() {
    //Categoria a mostrar para FAQS
    const categoryNames = ref(["Sobre el Consorcio"]);

    return {
      categoryNames,
    };
  },
};
</script>

<style scoped>

h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
  font-size: 28px;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,h4,h5{
  font-family: "Merriweather" !important;
}
p,span,li,a{
  font-family: "Montserrat" !important;
}
p,a,span,li{
  font-size: 18px;
}

h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
}

.seccion-1 {
  margin-top: 3rem;
}
.seccion-1 h3 {
  color: #009644;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 4px;
  font-family: "Montserrat";
}

.seccion-1 p {
  text-align: justify;
}
.seccion-1 .logo-fagri,
.seccion-1 .logo-ibce {
  width: 100%;
  margin-top: 50px;
}
.seccion-1 .logo-fundeforest {
  width: 300px;
  margin-top: 50px;
}
.seccion-1 .imagen {
  text-align: center;
  width: 370px;
}

@media (max-width: 765px) {
  .seccion-1 {
    margin-top: 3rem !important;
  }
}
</style>
