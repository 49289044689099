<template>
  <!-- Header START -->
  <header class="navbar-light navbar-sticky">
    <!-- Logo Nav START -->
    <nav class="navbar navbar-expand-xl">
      <div class="container">
        <!-- Logo START -->
        <a class="navbar-brand" @click.prevent="goHome" style="cursor: pointer">
          <img
            class="light-mode-item navbar-brand-item"
            src="/assets/images/logo-via-verde-0.jpg"
            alt="logo Vía Verde"
            title="Vía Verde"
          />
        </a>

        <div class="div-logo-alinvest-celular">
          <img
            class="light-mode-item mb-2"
            src="/assets/images/logos_union_europea_alinvest_global_gateway.jpg"
            alt="logo AL-INVEST Verde"
            title="AL-INVEST Verde"
            width="140"
          />
        </div>
        <!-- Logo END -->

        <!-- Responsive navbar toggler -->
        <button
          class="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-animation">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <!-- Main navbar START -->
        <div class="navbar-collapse w-100 collapse" id="navbarCollapse">
          <!-- Nav Main menu START -->
          <ul class="navbar-nav navbar-nav-scroll mx-auto menu">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="demoMenu"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="¿Quiénes somos?"
                >¿Quiénes somos?</a
              >
              <ul class="dropdown-menu" aria-labelledby="demoMenu">
                <li>
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="goToViaVerde"
                    title="Vía Verde"
                    >Vía Verde</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="goToAlinvest"
                    title="AL-INVEST Verde"
                    >AL-INVEST Verde</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="goToConsorcio"
                    title="Consorcio"
                    >Consorcio</a
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                @click="goToDiagnostics"
                style="cursor: pointer"
                title="Autodiagnóstico"
                >Autodiagnóstico</a
              >
            </li>

            <li
              class="nav-item"
              @click="goToGuiaSearch"
              style="cursor: pointer"
            >
              <a class="nav-link"
              style="cursor: pointer"
              title="Guía - Informe de Exportación">Guía para exportar a la Unión Europea</a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                @click="goToCategoryCourses"
                style="cursor: pointer"
                title="Capacitaciones"
                >Capacitaciones</a
              >
            </li>
          </ul>

          <div class="nav my-3 my-xl-0 px-4 flex-nowrap align-items-center">
            <div class="nav-item w-100">
              <img
                src="/assets/images/logos_union_europea_alinvest_global_gateway.jpg"
                width="270"
                alt="logo Global Gateway, AL-INVEST Verde y Unión Europea"
                title="Global Gateway, AL-INVEST Verde y Unión Europea"
              />
            </div>
          </div>
        </div>
        <!-- Main navbar END -->

        <!-- Iniciar Sesion START -->
        <div v-if="!isAuthenticated" class="dropdown ms-1 ms-lg-0">
          <a
            class="avatar avatar-sm p-0"
            href="#"
            id="profileDropdown"
            role="button"
            data-bs-auto-close="outside"
            data-bs-display="static"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="cursor: pointer"
          >
            <img
              class="avatar-img rounded-circle"
              src="/assets/images/avatar/13.jpg"
              alt="icon usuario"
              title="Iniciar Sesión"
            />
            <br />
            <div class="nombre_usuario">Iniciar sesión</div>
          </a>

          <ul
            class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
            aria-labelledby="profileDropdown"
          >
            <!-- Profile info -->
            <li class="px-3 mb-3">
              <div class="d-flex align-items-center">
                <!-- Avatar -->
                <div class="avatar me-3">
                  <img
                    class="avatar-img rounded-circle shadow"
                    src="/assets/images/avatar/13.jpg"
                    alt="avatar"
                    title="Avatar Usuario"
                  />
                </div>
                <div>
                  <a class="h6" @click="goToLogin"> SESIÓN SIN INICIAR</a>
                  <p class="small m-0"></p>
                </div>
              </div>
            </li>
            <li><hr class="dropdown-divider" /></li>
            <!-- Links -->
            <li>
              <a
                class="dropdown-item"
                @click="goToProfile"
                style="cursor: pointer"
                title="Iniciar Sesión"
                ><i class="bi bi-person fa-fw me-2"></i>Iniciar Sesión</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                @click="goToRegister"
                style="cursor: pointer"
                title="Registrarse"
                ><i class="bi bi-person fa-fw me-2"></i>Registrarse</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
          </ul>
        </div>
        <!-- Iniciar Sesion START -->

        <!-- Profile START -->
        <div v-if="isAuthenticated" class="dropdown ms-1 ms-lg-0">
          <a
            class="avatar avatar-sm p-0"
            href="#"
            id="profileDropdown"
            role="button"
            data-bs-auto-close="outside"
            data-bs-display="static"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div style="width: 50px; text-align: center">
              <img
                class="avatar-img rounded-circle"
                src="/assets/images/avatar/user-login.jpg"
                alt="avatar de Usuario"
                title="avatar de Usuario"
                style="max-width: 35px"
              /><br />
              <div class="nombre_usuario">{{ shortName }}</div>
            </div>
          </a>

          <ul
            class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
            aria-labelledby="profileDropdown"
          >
            <!-- Profile info -->
            <li class="px-3 mb-3">
              <div class="d-flex align-items-center">
                <!-- Avatar -->
                <div class="avatar me-3">
                  <img
                    class="avatar-img rounded-circle shadow"
                    src="/assets/images/avatar/user-login.jpg"
                    alt="avatar de Usuario"
                    title="avatar de Usuario"
                  />
                </div>
                <div>
                  <a class="h6" href="#">{{ user?.name }}</a>
                  <p class="small m-0">{{ user?.email }}</p>
                </div>
              </div>
            </li>
            <li><hr class="dropdown-divider" /></li>
            <!-- Links -->
            <li>
              <a
                class="dropdown-item"
                @click="goToProfile"
                style="cursor: pointer"
                title="Ver Perfil"
                ><i class="fas fa-user fa-fw me-2"></i>Ver Perfil</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                @click="goToProfileDiagnostics"
                style="cursor: pointer"
                title="Autodiagnósticos realizados"
                ><i class="fas fa-book-reader fa-fw me-2"></i>Autodiagnósticos
                realizados</a
              >
            </li>
             <li>
              <a
                class="dropdown-item"
                @click="goToProfileEnrollments"
                style="cursor: pointer"
                title="Capacitaciones inscritas"
                ><i class="fas fa-user-graduate fa-fw me-2"></i>Capacitaciones inscritas</a
              >
            </li>
            <li>
              <a
                class="dropdown-item bg-danger-soft-hover"
                @click.prevent="logout"
                style="cursor: pointer"
                title="Cerrar Sesión"
              >
                <i class="fas fa-sign-out-alt fa-fw me-2"></i>Cerrar sesión
              </a>
            </li>

            <li><hr class="dropdown-divider" /></li>
          </ul>
        </div>
        <!-- Profile START -->
      </div>
    </nav>
    <!-- Logo Nav END -->
  </header>
  <!-- Header END -->
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/modules/auth/stores/login';

export default {
  setup() {
    const loginStore = useLoginStore();
    const router = useRouter();

    const user = ref(loginStore.user); // Hacer que user sea reactivo
    const isAuthenticated = loginStore.isAuthenticated;
    const shortName = computed(() => user.value?.name?.split(" ")[0]);

    // Watch para detectar cambios en user
    watch(
      () => loginStore.user, // Observar el cambio en loginStore.user
      (newValue) => {
        user.value = newValue; // Actualizar el valor de user
      },
      { immediate: true, deep: true }
    );

    const logout = () => {
      loginStore.logoutUser();
      router.replace({ name: 'login' });
    };

    const goToProfile = () => {
      router.push({ name: 'profile' });
    };

    const goHome = () => {
      router.push({ name: 'home' });
    };

    // Otras funciones de navegación
    const goToProfileDiagnostics = () => router.push({ name: 'profile-diagnostics' });
    const goToDiagnostics = () => router.push({ name: 'showDiagnostics' });
    const goToViaVerde = () => router.push({ name: 'viaverde' });
    const goToAlinvest = () => router.push({ name: 'alinvest' });
    const goToConsorcio = () => router.push({ name: 'consorcio' });
    const goToLogin = () => router.push({ name: 'login' });
    const goToRegister = () => router.push({ name: 'register' });
    const goToCategoryCourses = () => router.push({ name: 'lista-categorias' });
    const goToProfileEnrollments = () => router.push({ name: 'profile-enrollments' });
    const goToGuiaSearch = () => router.push({ name: 'guia-search' });

    return {
      user,
      shortName,
      isAuthenticated,
      logout,
      goToProfile,
      goHome,
      goToProfileDiagnostics,
      goToDiagnostics,
      goToLogin,
      goToRegister,
      goToCategoryCourses,
      goToViaVerde,
      goToAlinvest,
      goToConsorcio,
      goToGuiaSearch,
      goToProfileEnrollments,
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,h4,h5{
  font-family: "Merriweather" !important;
}

p,span,li,a{
  font-family: "Montserrat" !important;
}

.menu > li {
  border-right: #cacaca ridge 1px;
}
.menu li a{
  font-size: 14px;
  
}
.menu > li:last-child {
  border-right: none;
}

.navbar-brand-item{
  height: 38px!important;
}

@media screen and (min-width: 1200px) {
  .div-logo-alinvest-celular {
    display: none;
  }
}

@media (max-width: 765px) {
  .nombre_usuario{
    display: none;
  }
}

</style>
