import ProfileLayout from "@/modules/profile-managment/layouts/ProfileMLayout.vue"
export default {
  name: "profile",
  redirect: { name: 'showProfile' },
  component: ProfileLayout,
    children: [
      {
        path: '',
        name: 'showProfile',
        component: () => import('@/modules/profile-managment/components/ShowProfile.vue'),
      },
      {
        path: 'editar',
        name: 'edit',
        component: () => import('@/modules/profile-managment/components/EditProfile.vue'),
      },
      {
        path: 'autodiagnostico',
        name: 'profile-diagnostics',
        component: () => import('@/modules/profile-managment/components/ShowDiagnostic.vue'),
      },
      {
        path: 'inscripcion',
        name: 'profile-enrollments',
        component: () => import('@/modules/profile-managment/components/ShowEnrollment.vue'),
      },
    ],
};