<template>
  <!-- =======================
Footer START -->
  <footer class="pt-5 bg-light">
    <div class="container">
      <!-- Row START -->
      <div class="row g-4">
        <!-- Widget 2 START -->
        <div class="col-lg-3">
          <div class="row g-4">
            <!-- Link block -->
            <div class="col-6 col-md-12">
              <h1 class="mb-2 mb-md-4">Contenido</h1>
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a
                    class="nav-link text-dark"
                    title="Acerca de nosotros"
                    @click="goToViaVerde"
                    style="cursor: pointer"
                    >¿Quiénes somos?</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link text-dark"
                    title="Autodiagnóstico"
                    @click="goToDiagnostics"
                    style="cursor: pointer"
                    >Autodiagnóstico</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link text-dark"
                    @click="goToGuiaSearch"
                    style="cursor: pointer"
                    title="Guía - Informe de Exportación"
                    >Guía para exportar a la Unión Europea</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link text-dark"
                    @click="goToCategoryCourses"
                    title="Capacitaciones"
                    style="cursor: pointer"
                    >Capacitaciones</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Widget 2 END -->

        <!-- Widget 1 START -->
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-12">
              <div class="center-div">
                <!-- logo -->
                <a class="me-0" href="index.html">
                  <img
                    class="light-mode-item"
                    style="height: 90px"
                    src="/assets/images/logo-via-verde-transparente-0.png"
                    alt="logo Vía Verde"
                    title="log VÍA VERDE"
                  />
                  <img
                    class="dark-mode-item h-70px"
                    src="/assets/images/logo-via-verde-transparente-0.png"
                    alt="logo Vía Verde"
                    title="log VÍA VERDE"
                  />
                </a>

                <p class="text-dark">
                  <!-- Económicamente viable, ambientalmente sostenible y socialmente
                  responsable -->
                </p>

                <p class="my-3 text-dark">
                  El Proyecto Vía Verde está financiado por la Unión Europea en
                  el marco del programa AL-INVEST Verde
                </p>
                <!-- LOGO ALINVEST-->
                <div>
                  <img
                    class="light-mode-item rounded-3"
                    src="/assets/images/logos_union_europea_alinvest_global_gateway.jpg"
                    alt="logo AL-INVEST Verde, Global Gateway y Unión Europea"
                    title="logo AL-INVEST Verde, Global Gateway y Unión Europea"
                    width="350"
                  />
                </div>

                <p class="my-3 text-dark">Consorcio compuesto por:</p>

                <div class="contenedor-flex">
                  <!-- LOGO FAGRI-->
                  <div>
                    <img
                      class="light-mode-item"
                      src="/assets/images/logo-fagri.png"
                      alt="Logo FAGRI"
                      title="FAGRI"
                      width="160"
                    />
                  </div>
                  <!-- LOGO IBCE-->
                  <div>
                    <img
                      class="light-mode-item"
                      src="/assets/images/ibce-normal.png"
                      alt="Logo IBCE"
                      title="IBCE"
                      width="120"
                    />
                  </div>

                  <!-- LOGO FUNDEFOREST-->
                  <div>
                    <img
                      class="light-mode-item"
                      src="/assets/images/logo-Fundeforest.png"
                      alt="Logo FUNDEFOREST"
                      title="FUNDEFOREST"
                      width="90"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Widget 1 END -->

        <!-- Widget 3 START -->
        <div class="col-lg-3">
          <h1 class="mb-2 mb-md-4">Contáctenos</h1>
          <!-- Time -->
          <p class="mb-2 text-dark">
            WhatsApp:
            <img
              src="assets/images/icon-whatsapp.png"
              width="20"
              title="icon whatsapp"
              alt="icon whatsapp"
            />
            <a
              href="https://wa.me/59162387496"
              style="color: black"
              title="whatsapp de contacto"
            >
              (+591) 62387496
            </a>
            <!-- <span class="d-block small">(9:AM to 8:PM IST)</span> -->
          </p>

          <p class="mb-0 text-dark">
            Email:<a
              href="mailto:viaverde@ibce.org.bo"
              style="color: black"
              title="email de contacto"
            >
              viaverde@ibce.org.bo</a
            >
          </p>
          <p class="mb-0 text-dark">
            Fax:<span class="h6 fw-light ms-2">(+591-3) 3324241</span>
          </p>
          <!-- <p class="mb-0 text-dark">
            Casilla:<span class="h6 fw-light ms-2 "
              >3440</span
            >
          </p> -->
          <p class="mb-0 text-dark">Santa Cruz - Bolivia</p>
          <!-- Social media icon -->
          <ul class="list-inline mb-0 mt-3">
            <li class="list-inline-item">
              <a
                class="btn btn-white btn-sm shadow px-2 text-facebook"
                href="https://www.facebook.com/profile.php?id=61561041695334"
                title="icon Facebook"
                ><i class="fab fa-fw fa-facebook-f"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-white btn-sm shadow px-2 text-instagram"
                href="https://www.instagram.com/viaverdebo/?igsh=ODE4b2VsN3h3Y3Y1"
                title="icon instagram"
                ><i class="fab fa-fw fa-instagram"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-white btn-sm shadow px-2 text-twitter"
                href="https://x.com/ViaVerdeBo?t=yQqB54aVpYJZekatWRaiHw&s=09"
                title="icon X"
                ><img
                  src="assets/images/icon-x.png"
                  width="20"
                  title="icon X"
                  alt="icon X"
              /></a>
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-white btn-sm shadow px-2 text-linkedin"
                href="https://www.linkedin.com/company/v%C3%ADa-verde-bolivia/"
                title="icon VÍA VERDE"
                ><i class="fab fa-fw fa-linkedin-in"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-white btn-sm shadow px-2 text-tiktok"
                href="https://www.tiktok.com/@viaverdebo?is_from_webapp=1&sender_device=pc"
                title="Icon TIKTOK"
                ><i class="fab fa-fw fa-tiktok"></i
              ></a>
            </li>
          </ul>
        </div>
        <!-- Widget 3 END -->
      </div>
      <!-- Row END -->

      <!-- Divider -->
      <hr class="mt-4 mb-0" />

      <!-- Bottom footer -->
      <div class="py-3">
        <div class="container px-0">
          <div
            class="d-lg-flex justify-content-between align-items-center py-3 text-center text-md-left"
          >
            <!-- copyright text -->
            <!-- <div class="text-body text-primary-hover">
              Copyrights ©2024 IBCE.
            </div> -->
            <!-- copyright links-->
            <div class="justify-content-center mt-3 mt-lg-0">
              <ul class="nav list-inline justify-content-center mb-0">
                <!-- <li class="list-inline-item">
                  <a class="nav-link text-body-secondary" href="#"
                    >Preguntas y respuestas</a
                  >
                </li> -->
                <!-- <li class="list-inline-item"><a class="nav-link pe-0" href="#">Privacy policy</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- =======================
Footer END -->
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    const goToViaVerde = () => {
      router.push({ name: "viaverde" });
    };

    const goToDiagnostics = () => {
      router.push({ name: "showDiagnostics" });
    };

    const goToGuiaSearch = () => {
      router.push({ name: "guia-search" });
    };

    const goToCategoryCourses = () => {
      router.push({ name: "lista-categorias" });
    };

    return {
      goToViaVerde,
      goToDiagnostics,
      goToGuiaSearch,
      goToCategoryCourses,
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
  font-size: 28px;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,
h4,
h5 {
  font-family: "Merriweather" !important;
}
p,
span,
li,
a {
  font-family: "Montserrat" !important;
}
p,
a,
span {
  font-size: 18px;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
}
</style>

<style scoped>
.contenedor-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.contenedor-flex div {
  margin-left: 10px;
}
</style>
