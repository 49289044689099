<template>
  <div>
    <!-- Floating Action Button -->
    <button
      type="button"
      class="btn btn-primary btn-floating"
      @click="openModal"
    >
      <i class="bi bi-question-lg"></i>
    </button>

    <!-- Custom Modal -->
    <Modal
      :title="'Preguntas más frecuentes'"
      :isVisible="isModalVisible"
      @close="closeModal"
    >
      <div
        v-if="loading"
        class="alert alert-info d-flex justify-content-center align-items-center text-center"
      >
        <!-- Spinner -->
        <div
          class="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></div>
        <!-- Mensaje -->
        Cargando contenido, espere por favor...
      </div>

      <!-- FAQ START -->
      <div v-else>
        <!-- Mostrar mensaje si no hay contenido -->
        <div v-if="noContent" class="text-center">
          <div class="alert alert-dark">
            Esta sección no contiene preguntas frecuentes.
          </div>
        </div>

        <!-- Mostrar contenido si hay registros -->
        <div
          v-else
          class="accordion accordion-icon accordion-bg-light"
          id="accordionExample2"
        >
          <!-- Iterate over categories -->
          <div v-for="category in faqs" :key="category.id">
            <h5><i class="fas fa-caret-right"></i> {{ category.name }}</h5>
            <hr />
            <!-- Iterate over questions in each category -->
            <div
              class="accordion-item mb-3"
              v-for="faq in category.faqs"
              :key="faq.id"
            >
              <h6 class="accordion-header font-base" :id="'heading-' + faq.id">
                <button
                  class="accordion-button fw-bold rounded d-inline-block collapsed d-block pe-5"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse-' + faq.id"
                  aria-expanded="false"
                  :aria-controls="'collapse-' + faq.id"
                >
                  {{ faq.question }}
                </button>
              </h6>
              <!-- Body -->
              <div
                :id="'collapse-' + faq.id"
                class="accordion-collapse collapse"
                :aria-labelledby="'heading-' + faq.id"
                data-bs-parent="#accordionExample2"
              >
                <div class="accordion-body mt-3">
                  {{ faq.faq_answer.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FAQ END -->
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useToast } from "vue-toastification";
import { POSITION } from "vue-toastification";
import { useFaqStore } from "@/modules/common/stores/faq";
import Modal from "./ModalComponent.vue";

export default {
  components: {
    Modal,
  },
  props: {
    categoryNames: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const isModalVisible = ref(false);
    const faqStore = useFaqStore();

    const openModal = async () => {
      isModalVisible.value = true;
      await faqStore.fetchFaqs(props.categoryNames);
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const showErrorValidationToast = (message) => {
      const toast = useToast();
      toast.error(message, {
        position: POSITION.TOP_CENTER,
      });
    };

    // Fetch FAQs on component mount
    onMounted(async () => {
      faqStore.fetchFaqs(props.categoryNames);
    });

    //FAQS
    const faqs = computed(() => faqStore.faqs);
    const loading = computed(() => faqStore.loading);
    const noContent = computed(() => {
      return faqs.value.every((category) => category.faqs.length === 0);
    });

    watch(
      () => faqStore.error,
      (newError) => {
        if (newError) {
          showErrorValidationToast(newError);
        }
      }
    );

    return {
      isModalVisible,
      openModal,
      closeModal,
      faqs,
      loading,
      noContent,
    };
  },
};
</script>

<style scoped>
.btn-floating {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6; /* Asegúrate de que esté por debajo del modal */
}
@media (max-width: 767.98px) {
  .btn-floating {
    right: 20px;
    bottom: 60px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}
</style>
