import HomePage from "@/modules/landing/pages/HomePage";
import ViaVerdePage from "@/modules/landing/pages/ViaVerdePage";
import AllinvestPage from "@/modules/landing/pages/AllinvestPage";
import ConsorcioPage from "@/modules/landing/pages/ConsorcioPage";

export default {
  path: '/',
  children: [
    {
      path: '',
      name: 'home',
      component: HomePage,
    },
    {
      path: 'viaverde',
      name: 'viaverde',
      component: ViaVerdePage,
    },
    {
      path: 'al-invest-verde',
      name: 'alinvest',
      component: AllinvestPage,
    },
    {
      path: 'consorcio',
      name: 'consorcio',
      component: ConsorcioPage,
    },
  ],
};
