export default {
  name: "courses",
  redirect: { name: 'course-detail' },
  children: [
    {
      path: ":name",
      name: "course-detail",
      component: () => import("@/modules/course/components/CourseDetail.vue"),
    },
  ],
};
