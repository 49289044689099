<template>
  <!-- **************** MAIN CONTENT START **************** -->
  <main>
    <!-- =======================
    Slider Carousel Bootstrap 5.3 START -->
    <div
      id="carouselExampleIndicators"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
      style=""
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="/assets/images/banner/campesino.jpg"
            class="d-block w-100"
            alt="imagen de campesino"
            title="Hombre campesino cultivando"
          />
        </div>
        <div class="carousel-item">
          <img
            src="/assets/images/banner/carpintero.jpg"
            class="d-block w-100"
            alt="imagen de carpintero"
            title="Carpintero trabajando"
          />
        </div>
        <div class="carousel-item">
          <img
            src="/assets/images/banner/ganado-boliviano.jpg"
            class="d-block w-100"
            alt="imagen de ganado boliviano"
            title="Ganado boliviano"
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previo</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Siguiente</span>
      </button>
    </div>
    <!-- =======================
    Slider Carousel Bootstrap 5.3 END -->

    <!-- ======== SECTORES DE APOYO START ======== -->
    <section class="seccion-1">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="" style="">
              Conozca los sectores de apoyo a la internacionalización
              <br />hacia la Unión Europea:
            </p>
          </div>

          <div class="col-md-4 elemento1">
            <img
              src="assets/images/AGRICOLA.jpg"
              title="Sector Agrícola"
              alt="Imagen de sector Agrícola"
            />
          </div>
          <div class="col-md-4 elemento1">
            <img
              src="assets/images/FORESTAL.jpg"
              title="Sector Forestal"
              alt="Imagen de Sector Forestal"
            />
          </div>
          <div class="col-md-4 elemento1">
            <img
              src="assets/images/PECUARIO.jpg"
              title="Sector Pecuario"
              alt="Imagen de Sector Pecuario"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- ======== SECTORES DE APOYO END ======== -->

    <div class="container seccion-3">
      <div class="row">
        <div class="col-md-6 contenido">
          <div class="mb-4 titulo-pagina">Vía <br />Verde</div>

          <div class="subtitulo-pagina mb-4">Exportar con Sostenibilidad</div>

          <p>
            Es una Plataforma gratuita que busca impulsar al sector agropecuario
            y forestal a través del fortalecimiento de sus capacidades, para
            prepararlos hacia su internacionalización al mercado de la Unión
            Europea.
          </p>

          <p><a @click="goToViaVerde" class="btn btn-primary">LEER MÁS</a></p>
        </div>

        <div class="col-md-6">
          <img
            src="assets/images/Que-es-la-huella-ecologica-mundial.jpg"
            alt="Imagen de una persona responsable usando una computadora"
            title="Persona responsable de una mipymes frente a computador"
            class="img-fluid img-tablet img-responsive rounded-5"
          />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <h3>IMPACTO</h3>

          <h2 class="mb-5 mt-3">
            El impacto esperado por "Vía Verde" <br />
            Exportar con sostenibilidad
          </h2>
        </div>
      </div>
    </div>

    <!-- ======== DATOS START ======== -->
    <div
      class="seccion-2"
      style="background-image: url('assets/images/fondo-2.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3 icon-box">
            <!-- Icon 1 -->
            <img src="assets/images/icon-4.png" width="64" title="icon mipymes utilizando la plataforma" />

            <h3>{{ profileCount }}+</h3>
            <p>Mipymes  utilizando <br />la plataforma</p>
          </div>
          <div class="col-md-3 icon-box">
            <!-- Icon 2 -->
            <img src="assets/images/icon-3.png" width="64" title="icon exportados a la Unión Europea" />
            <h3>USD643.607.336</h3>
            <p>Exportados a la Unión Europea (INE, 2023)</p>
          </div>
          <div class="col-md-3 icon-box">
            <!-- Icon 3 -->
            <img src="assets/images/icon-1.png" width="64" title="Países miembros de la Unión Europea"/>
            <h3>27</h3>
            <p>Países miembros de la <br />Unión Europea</p>
          </div>
          <div class="col-md-3 icon-box">
            <!-- Icon 4 -->
            <img src="assets/images/icon-2.png" width="64" title="Consumidores europeos (Eurostat, 2023)"/>
            <h3>448.753.823</h3>
            <p>Consumidores europeos (Eurostat, 2023)</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ======== DATOS END ======== -->

    <!-- =======================
    About START -->
    <section class="seccion-4">
      <div class="container">
        <div class="row g-4 align-items-center">
          <div class="col-lg-12">
            <!-- Title -->
            <div style="text-align: center">
              <h2>
                Herramientas para potenciar su internacionalización al mercado
                de la Unión Europea
              </h2>
            </div>
            <!-- Image -->
          </div>

          <div class="col-md-12">
            <div class="herramientas-div">
              <div class="tarjeta rounded-3">
                <div class="imagen">
                  <img
                    src="/assets/images/Guia-exportacion.jpg"
                    class="rounded-3"
                    alt="banner Guía para la exportación"
                    title="Guía - Informe para exportar a la Unión Europea"
                  />
                </div>
                <div class="contenido">
                  <h3>Guía - Informe para exportar a la Unión Europea</h3>
                  <p>
                    Con esta Guía-Informe, podrá obtener información clave para
                    poder exportar a la Unión Europea.
                  </p>
                  <div class="boton" @click="goToGuiaSearch">
                    <input
                      type="button"
                      class="btn btn-outline-success"
                      Value="ACCEDER"
                    />
                  </div>
                </div>
              </div>

              <div class="tarjeta rounded-3">
                <div class="imagen">
                  <img
                    src="/assets/images/autodiagnostico-sostenibilidad.jpg"
                    class="rounded-3"
                    alt="Autodiagnóstico de Sostenibilidad"
                    title="Autodiagnóstico de Sostenibilidad"
                  />
                </div>
                <div class="contenido">
                  <h3>Autodiagnóstico de Sostenibilidad</h3>
                  <p>
                    Este Autodiagnóstico podrá ayudarlo a adaptar todas sus
                    operaciones a los requerimientos de mercado altamente
                    competitivos.
                  </p>
                  <div class="boton" @click="goToDiagnostics">
                    <input
                      type="button"
                      class="btn btn-outline-success"
                      Value="ACCEDER"
                    />
                  </div>
                </div>
              </div>

              <div class="tarjeta rounded-3">
                <div class="imagen">
                  <img
                    src="/assets/images/capacitaciones.jpg"
                    class="rounded-3"
                    alt="Capacitaciones"
                    title="Capacitaciones"
                  />
                </div>
                <div class="contenido">
                  <h3>Capacitaciones</h3>
                  <p>
                    Esta sección le permitira fortalecer sus conocimientos que
                    coadyuven en la práctica para exportar a la Unión Europea.
                  </p>
                  <div class="boton" @click="goToCategoryCourses">
                    <input
                      type="button"
                      class="btn btn-outline-success"
                      Value="ACCEDER"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- =======================
    About END -->
  </main>
  <FloatingActionButton :categoryNames="categoryNames" />
  <HomeModal
    :title="''"
    :isVisible="isModalVisible"
    @closeHomeModal="closeModal"
  >
    <div @click="goToRegister">
      <img
        src="assets/images/quieres-exportar-tus-productos-a-la-Union-Europea.jpg"
        alt="banner preguntando si desea exportar sus productos a la Unión Europea"
        title="Desea exportar sus productos a la Unión Europea"
      />
    </div>

    <!-- <p style="color: #333; font-weight: bold; margin-bottom: 10px">
      Descubra nuevas oportunidades para su empresa
    </p>
    <p style="color: #555; line-height: 1.6; text-align: justify">
      Le invitamos a registrarse en nuestra plataforma para tener
      acceso completo a herramientas que le ayudarán a evaluar la sostenibilidad
      de su empresa, aprender a través de capacitaciones y generar guías de
      exportación hacia la Unión Europea. <br>Es totalmente gratuito.
      <b>¡Aproveche esta oportunidad!</b>
      
    </p> -->
  </HomeModal>
  <!-- **************** MAIN CONTENT END **************** -->
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useLoginStore } from "@/modules/auth/stores/login";
import FloatingActionButton from "@/modules/common/components/FloatingActionButton.vue";
import HomeModal from "../components/HomeModal.vue";

export default {
  components: {
    FloatingActionButton,
    HomeModal,
  },
  setup() {
    const router = useRouter();
    const isModalVisible = ref(false);
    const loginStore = useLoginStore();
    const profileCount = ref(0);

    const openModal = async () => {
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };
    const goToRegister = () => {
      router.push({ name: "register" });
    };

    const goToViaVerde = () => {
      router.push({ name: "viaverde" });
    };

    const goToGuiaSearch = () => {
      router.push({ name: "guia-search" });
    };

    const goToDiagnostics = () => {
      router.push({ name: "showDiagnostics" });
    };

    const goToCategoryCourses = () => {
      router.push({ name: "lista-categorias" });
    };

    watch(
      () => router.currentRoute.value,
      (newRoute) => {
        if (newRoute.name === "home" && !loginStore.isAuthenticated) {
          setTimeout(() => {
            isModalVisible.value = true;
          }, 3000); // Retraso de 3 segundos
        }
      },
      { immediate: true }
    );

    onMounted(async () => {
      await loginStore.fetchProfileCount();
      profileCount.value = loginStore.profileCount;
    });

    //Categoria a mostrar para FAQS
    const categoryNames = ref(["Página principal"]);

    return {
      categoryNames,
      isModalVisible,
      openModal,
      closeModal,
      goToRegister,
      profileCount,
      goToViaVerde,
      goToGuiaSearch,
      goToDiagnostics,
      goToCategoryCourses,
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,
h4,
h5 {
  font-family: "Merriweather" !important;
}
p,
span,
li,
a,
button {
  font-family: "Montserrat" !important;
}
p {
  font-size: 18px;
}

@media (max-width: 767.98px) {
  .custom-margin {
    margin-top: 1rem;
  }
}
.seccion-1 {
  background-color: #f7f7f7;
  padding-bottom: 4rem;
}
.seccion-1 div p {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 3.5rem;
}
.seccion-1 .elemento1 {
  text-align: center;
}
.seccion-1 .elemento1 img {
  max-width: 320px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.seccion-2 {
  color: #ffffff;
  padding: 70px 0;
}
.seccion-2 .icon-box {
  text-align: center;
}
.seccion-2 p {
  font-weight: 500;
  font-size: 18px;
  text-shadow: 1px 1px 5px #000000;
}
.seccion-2 h3 {
  color: #ffffff;
}
.seccion-2 img {
  width: 70px;
}

.seccion-3 {
  margin-top: 5rem !important;
}
.seccion-3 h3 {
  color: #009644;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 4px;
  font-family: "Montserrat";
}

.seccion-3 .titulo-pagina {
  font-family: "Merriweather";
  font-size: 100px;
  font-weight: 900;
  line-height: 100%;
  color: #00a052;
}
.seccion-3 .subtitulo-pagina {
  color: #009644;
  font-weight: bold;
}

.seccion-3 {
  font-family: "Montserrat";
  font-size: 18px;
  text-align: justify;
  margin-top: 100px;
}

.herramientas-div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.herramientas-div .tarjeta {
  margin: 0px 15px;
  padding-bottom: 15px;
  width: 25%;
  min-width: 340px;
  background-color: #E9F3E9;
}
.herramientas-div .tarjeta .imagen {
}
.herramientas-div .tarjeta .contenido {
  text-align: justify;
  margin-top: 20px;
  padding: 5px 20px;
}
.herramientas-div .tarjeta .contenido .boton {
  text-align: center;
}
.herramientas-div .tarjeta .contenido h3 {
  font-size: 1.4rem;
  text-align: center;
}

@media (max-width: 765px) {
  section {
    padding-top: 0rem;
    padding-bottom: 2.8rem;
  }
  .seccion-1 {
    margin-top: 0rem !important;
    padding-top: 3rem;
  }
  .seccion-1 .elemento1 {
    margin-top: 20px;
  }
  .seccion-1 div p {
    margin-bottom: 1rem;
  }
  .seccion-2 {
    /* background-image:none!important; */
    /* color: #000000; */
    padding: 30px 0px;
    background-size: cover;
    background-position: center;
  }
  .seccion-2 p {
    font-weight: 500;
    text-shadow: none;
  }
  .seccion-3 {
    margin-top: 4rem !important;
  }

  .seccion-4 {
    margin-top: 2rem !important;
  }

  .herramientas-div .tarjeta {
    margin-top: 15px;
  }
}
</style>
