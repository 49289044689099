<template>
  <!-- =======================
Main banner START -->
<section class="pt-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- Card item START -->
          <div
            class="card overflow-hidden h-100px h-xl-200px rounded-3"
            style="
              background-image: url(/assets/images/bg/fondo-acercade-viaverde.jpg);
              background-position: center left;
              background-size: cover;
            "
          >
            <!-- Background dark overlay -->
            <div class="bg-overlay bg-dark opacity-6"></div>

            <!-- Card image overlay -->
            <div class="card-img-overlay d-flex align-items-start flex-column">
              <!-- Card overlay Top -->
              <!-- <div class="w-100 mb-auto d-flex justify-content-end">
							<button class="btn btn-sm btn-white mb-0"><i class="bi bi-share"></i> Share</button>
						</div> -->
              <!-- Card overlay bottom -->
              <div class="w-100 mt-auto">
                <div class="row p-0 p-sm-3">
                  <div class="col-11 col-lg-7">
                    <!-- Title -->
                    <!-- <h1 class="text-white">¿Preparado para exportar? responde al Diagnóstico</h1>
									<p class="text-white mb-0">Una forma efectiva de ver que capacidades falta desarrollar es respondiendo al Diagnóstico.</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card item END -->
        </div>
      </div>
    </div>
  </section>
  <!-- =======================
Main banner END -->

  <div class="container seccion-1">
    <div class="row">
      <div class="col-md-6 contenido">
        <h2>¿Qué es AL-INVEST Verde?</h2>

        <p>
          Es un Programa financiado por la Comisión Europea para promover el
          crecimiento sostenible y la creación de empleo en América Latina. Su
          objetivo es apoyar la transición hacia una economía baja en carbono,
          eficiente en recursos y más circular, para facilitar la implementación
          de modelos de producción sostenibles.
        </p>
      </div>

      <div class="col-md-6">
        <img
          src="assets/images/logos_union_europea_alinvest_global_gateway.jpg"
          alt="Logo Al-Invest Verde, Global Gateway, Unión Europea"
          title="Logo Al-Invest Verde, Global Gateway, Unión Europea"
          class="img-fluid img-tablet img-responsive"
        />
      </div>
    </div>
  </div>
  <!--FIN CONTAINER-->

  <div class="container seccion-2">
    <div class="row">
      <div class="col-md-12 contenido">
        <h3>COMPONENTES</h3>
        <h2>El Programa se divide en tres componentes</h2>
        <p>
          Dichos componentes trabajan, respectivamente con pequeñas empresas
          latinoamericanas, instituciones públicas de la región y la gestión de
          derechos de propiedad intelectual.
        </p>
      </div>
    </div>

    <!--- COMPONENTES -->
    <div class="row">
      <div class="col-md-12">
        <div class="caja-flex">
          <div>
            <div class="">
              <img src="assets/images/icon-planta.png" title="INNOVACIÓN Y TRANSICIÓN VERDE DE LAS PYMES" width="70" />
              <h2 class="mt-3">COMPONENTE 1:</h2>
              <h3>INNOVACIÓN Y TRANSICIÓN VERDE DE LAS PYMES</h3>
              <p>
                Se ejecuta bajo el liderazgo de sequa junto a un Consorcio
                Ejecutor, conformado por diversas organizaciones de la región.
                Tiene como principal objetivo la gestión de fondos para la
                puesta en marcha de proyectos innovadores de asociación entre
                organizaciones de América Latina y de la Unión Europea.
              </p>
            </div>
          </div>
          <div>
            <div class="">
              <img src="assets/images/icon-planta.png" title="ASISTENCIA TÉCNICA AL SECTOR PÚBLICO" width="70" />
              <h2 class="mt-3">COMPONENTE 2:</h2>
              <h3>ASISTENCIA TÉCNICA AL SECTOR PÚBLICO</h3>
              <p>
                Está implementado por la Fundación Internacional y para
                Iberoamérica de Administración y Políticas Públicas (FIIAPP), en
                consorcio con la Organización Internacional
                Ítalo-latinoamericana (IILA). Su objetivo es prestar asistencia
                técnica al sector público latinoamericano para fomentar cadenas&nbsp;&nbsp;
                de valor sostenibles.
              </p>
            </div>
          </div>
          <div>
            <div class="">
              <img src="assets/images/icon-planta.png" title="USO DE LA PROPIEDAD INTELECTUAL PARA EL DESAROLLO SOSTENIBLE" width="70" />
              <h2 class="mt-3">COMPONENTE 3:</h2>
              <h3>
                USO DE LA PROPIEDAD INTELECTUAL PARA EL DESAROLLO SOSTENIBLE
              </h3>
              <p>
                Está implementado por la Oficina de Propiedad Intelectual de la
                Unión Europea (EUIPO). Tiene como principal objetivo promover el
                uso de los Derechos de Propiedad Intelectual (DPI) como impulsor
                crucial de la competitividad y la innovación para proteger la
                generación de valor por parte de las pequeñas y medianas
                empresas (pymes).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container seccion-3">
    <div class="row antecedentes">
      <div class="col-md-12">
        <h2>Antecedentes</h2>
      </div>
      <div class="col-md-12">
        <div class="row antecedentes-div">
          <div class="col-md-3">
            <h3>AL-INVEST - 1994</h3>
          </div>
          <div class="col-md-9"><p>Nacimiento del Programa</p></div>
        </div>
        <div class="row antecedentes-div">
          <div class="col-md-3">
            <h3>AL-INVEST II - 2001</h3>
          </div>
          <div class="col-md-9">
            <p>Se pone en marcha la segunda edición del programa</p>
          </div>
        </div>
        <div class="row antecedentes-div">
          <div class="col-md-3">
            <h3>AL-INVEST III - 2003</h3>
          </div>
          <div class="col-md-9">
            <p>Dado el éxito del programa, en 2003 se inicia la tercera fase</p>
          </div>
        </div>

        <div class="row antecedentes-div">
          <div class="col-md-3">
            <h3>AL-INVEST IV - 2008</h3>
          </div>
          <div class="col-md-9">
            <p> Con el objetivo de promover la internacionalización de las pequeñas
            y medianas empresas de América Latina, en 2008 se lanza AL-INVEST
            IV</p>
          </div>
        </div>

        <div class="row antecedentes-div">
          <div class="col-md-3">
            <h3>AL-INVEST 5.0 - 2016</h3>
          </div>
          <div class="col-md-9">
            <p>Inició en 2016. Su ejecución hizo posible beneficiar a más de 49.000
            pequeñas empresas de la región, a través del impulso a su
            productividad y competitividad</p>
          </div>
        </div>

        <div class="row antecedentes-div">
          <div class="col-md-3">
            <h3>AL-INVEST Verde - 2021</h3>
          </div>
          <div class="col-md-9 ">
            <p >Lanzado en la gestión 2021, promueve el crecimiento sostenible y la creación de
            empleo en América Latina para apoyar la transición hacia una
            economía baja en carbono, eficiente en recursos y más circular. Se
            enfoca en el sector privado (pequeñas y medianas empresas),
            instituciones públicas y derechos de propiedad intelectual</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    <FloatingActionButton :categoryNames="categoryNames" />
</template>

<script>
import {ref } from "vue";
import FloatingActionButton from "@/modules/common/components/FloatingActionButton.vue";
export default {
  components: {
    FloatingActionButton,
  },
  setup() {
    //Categoria a mostrar para FAQS
    const categoryNames = ref(["Sobre AL-INVEST Verde"]);

    return {
      categoryNames,
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
  font-size: 28px;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,h4,h5{
  font-family: "Merriweather" !important;
}
p,span,li,a{
  font-family: "Montserrat" !important;
}
p,a,span{
  font-size: 18px;
}

h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,h4,h5{
  font-family: "Merriweather" !important;
}

p,span,li,a{
  font-family: "Montserrat" !important;
}


.seccion-1 {
  text-align: justify;
}
.seccion-1 img {
  margin-top: 50px;
}

.seccion-2 .contenido h3 {
  color: #009644;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 4px;
  font-family: "Montserrat";
}
.seccion-2 {
  margin-top: 50px;
  text-align: justify;
  /* margin: 0px 10px; */
  background-color: #ffffff;
}

.caja-flex {
  display: flex;
  justify-content: space-around;
}
.caja-flex > div {
  background-color: #50b678;
  margin: 0px 10px;
  padding: 40px 35px 20px 35px;
}
.caja-flex > div p {
  color: black;
  margin-top: 30px;
}
.caja-flex > div h2 {
  font-size: 20px;
  color: #005d1f;
}
.caja-flex > div h3 {
  font-size: 20px;
  color: #ffffff;
  text-align: left;
}

.antecedentes {
  margin-top: 4rem;
  margin-bottom: 5rem;
}

.antecedentes-div {
  margin-top: 1rem;
  text-align: justify;
}

.antecedentes-div div{
  font-family: "Montserrat" !important;
}
.antecedentes h3 {
  color: #009644;
  font-size: 20px;
  font-weight: normal;
}

@media (max-width: 765px) {
  .caja-flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .caja-flex > div {
    margin: 10px 10px;
  }
}
</style>
