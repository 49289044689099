<template>
  <div class="main-container">
    <Header v-if="!isAuthRoute"></Header>
    <div class="content">
      <router-view />
    </div>
    <Footer v-if="!isAuthRoute"></Footer>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";
import Header from "@/modules/common/components/HeaderComponent.vue";
import Footer from "@/modules/common/components/FooterComponent.vue";
export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const route = useRoute();
    const isAuthRoute = computed(() => {
      return route.name === "login" || route.name === "register" || route.name === "NotFound";
    });

    return {
      isAuthRoute,
    };
  },
};
</script>

<style scoped>

</style>