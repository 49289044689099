import { defineStore } from "pinia";
import apiClient from "@/plugins/axios";

export const useFaqStore = defineStore("faqs", {
  state: () => ({
    faqs: [],
    error: "",
    loading: false,
  }),
  actions: {
    async fetchFaqs(categoryNames) {
      this.loading = true;
      this.error = "";
      try {
        const response = await apiClient.get("/faqs", {
          params: {
            categories: categoryNames,
          },
        });
        return (this.faqs = response.data);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else {
          this.error =
            "Error al obtener el contenido de las Preguntas, verifique su conexión a internet o intente de nuevo más tarde.";
        }
        return null;
      } finally {
        this.loading = false;
      }
    },
  },
});
