<template>
    <main>
        <section class="pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <!-- Image -->
                        <img src="/assets/images/element/error404-01.svg" class="h-200px h-md-400px mb-4" alt="">
                        <!-- Title -->
                        <h1 class="display-1 text-danger mb-0">404</h1>
                        <!-- Subtitle -->
                        <h2>Oh no, algo salió mal!</h2>
                        <!-- info -->
                        <p class="mb-4">Ocurrió un error o esta página ya no existe.</p>
                        <!-- Button -->
                        <router-link replace to="/" class="btn btn-primary mb-0">Llévame a la página de inicio</router-link>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {

}
</script>

<style>

</style>