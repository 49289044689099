import { createRouter, createWebHashHistory } from "vue-router";
import authRouter from "@/modules/auth/router";
import landingRouter from "@/modules/landing/router";
import profileRouter from "@/modules/profile-managment/router";
import diagnosticRouter from "@/modules/diagnostic/router";
import categoryRouter from "@/modules/course/router/category";
import courseRouter from "@/modules/course/router/course";
import guiaRouter from "@/modules/guia/router/guia";
import NotFound404 from "@/modules/common/pages/NotFound404";

const routes = [
  {
    path: "/",
    ...landingRouter,
  },
  {
    path: "/auth",
    ...authRouter,
  },
  {
    path: "/perfil",
    ...profileRouter,
  },
  {
    path: "/autodiagnostico",
    ...diagnosticRouter,
  },
  {
    path: "/categorias",
    ...categoryRouter,
  },
  {
    path: "/guia-exportadora",
    ...guiaRouter,
  },
  {
    path: "/capacitacion",
    ...courseRouter,
  },
  {
    name: "notFound",
    path: "/:pathMatch(.*)*",
    component: NotFound404,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Si hay una posición guardada (cuando se usa el botón "atrás" o "adelante"), vuelve a esa posición
    if (savedPosition) {
      return savedPosition;
    } else {
      // Si no hay una posición guardada, desplázate al inicio de la página
      return { top: 0 };
    }
  }
});

// Add navigation guard
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/auth/login",
    "/auth/register",
    "/",
    "/viaverde",
    "/al-invest-verde",
    "/consorcio",
    "/autodiagnostico",
    "/categorias",
    "/guia-exportadora",
  ];
  const isPublicPage =
    publicPages.includes(to.path) || to.path.startsWith("/categorias");
  const authRequired = !isPublicPage;
  const loggedIn = localStorage.getItem("auth_token");

  // Bloquear el acceso directo a /autodiagnostico/preguntas
  if (
    to.name === "questions" &&
    localStorage.getItem("canAccessQuestions") !== "true"
  ) {
    // Si intenta acceder directamente sin haber seguido el flujo correcto
    return next("/autodiagnostico"); // Redirige a otra ruta, por ejemplo, la lista de diagnósticos
  }

  // Limpiar la clave después de acceder a la ruta "preguntas"
  if (to.name === "questions") {
    localStorage.removeItem("canAccessQuestions"); // Aquí eliminamos la clave
  }

  if (authRequired && !loggedIn) {
    return next("/auth/login");
  }

  if ((to.path === "/auth/login" || to.path === "/auth/register") && loggedIn) {
    return next("/");
  }

  next();
});

export default router;
