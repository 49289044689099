import DiagnosticLayout from "@/modules/diagnostic/layouts/DiagnosticLayout.vue"
export default {
  name: "diagnostic",
  redirect: { name: 'showDiagnostics' },
  component: DiagnosticLayout,
    children: [
      {
        path: '',
        name: 'showDiagnostics',
        component: () => import('@/modules/diagnostic/components/DiagnosticList.vue'),
      },
      {
        path: 'preguntas',
        name: 'questions',
        component: () => import('@/modules/diagnostic/components/DiagnosticQuestions.vue'),
      },
    ],
};