import { defineStore } from "pinia";
import apiClient from "@/plugins/axios";
import { reactive, ref } from "vue";
import { useToast } from "vue-toastification";

export const useLoginStore = defineStore("login", () => {
  const user = reactive(JSON.parse(localStorage.getItem("user")) || {}); // Usar reactive en lugar de ref
  const company = reactive(JSON.parse(localStorage.getItem("company")) || {}); // Usar reactive en lugar de ref
  const email = ref("");
  const password = ref("");
  const fieldErrors = ref({});
  const isSubmitting = ref(false);
  const isAuthenticated = ref(!!localStorage.getItem("auth_token"));
  const profileCount = ref(0); // Aquí almacenaremos la cantidad de perfiles

  const loginUser = async () => {
    const toast = useToast();
    isSubmitting.value = true;
    try {
      const response = await apiClient.post("/login", {
        email: email.value.toLowerCase(),
        password: password.value,
      });

      // Verificar si response.data.user y response.data.company existen
      if (response.data.user) {
        Object.assign(user, response.data.user); // Usar Object.assign para actualizar el objeto reactivo
        localStorage.setItem("user", JSON.stringify(user)); // Almacenar en localStorage
      }
      if (response.data.company) {
        Object.assign(company, response.data.company); // Usar Object.assign para actualizar el objeto reactivo
        localStorage.setItem("company", JSON.stringify(company)); // Almacenar en localStorage
      }

      // Almacenar el token en localStorage
      localStorage.setItem("auth_token", response.data.token);
      isAuthenticated.value = true;

      // Limpiar errores del formulario si el inicio de sesión es exitoso
      fieldErrors.value = {};

      isSubmitting.value = false;
      return { success: true };
    } catch (error) {
      isSubmitting.value = false;
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        if (errors) {
          fieldErrors.value[errors] = [message];
        }
      } else {
        // Manejo de errores de conexión
        if (error.response && error.response.status === 404) {
          toast.error("No existe la Cuenta de Usuario ingresada");
        } else if (error.code === "ERR_NETWORK") {
          toast.error(
            "No se pudo conectar al servidor. Por favor, intente nuevamente más tarde"
          );
        } else {
          toast.error(
            "Ocurrió un error inesperado. Por favor, intenta nuevamente"
          );
        }
      }
      return { success: false };
    }
  };

  const updateUser = (updatedUser) => {
    if (updatedUser) {
      Object.assign(user, updatedUser); // Usar Object.assign para actualizar el objeto reactivo
      localStorage.setItem("user", JSON.stringify(user)); // Actualizar en localStorage
    }
  };

  const updateCompany = (updatedCompany) => {
    if (updatedCompany) {
      Object.assign(company, updatedCompany); // Usar Object.assign para actualizar el objeto reactivo
      localStorage.setItem("company", JSON.stringify(company)); // Actualizar en localStorage
    }
  };

  const fetchProfileCount = async () => {
    try {
      const response = await apiClient.get("/profile-count");
      profileCount.value = response.data.profile_count;
    } catch (error) {
      const toast = useToast();
      if (error.code === "ERR_NETWORK") {
        toast.error(
          "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde"
        );
      } else {
        toast.error(
          "Ocurrió un error inesperado. Por favor, intenta nuevamente"
        );
      }
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user");
    localStorage.removeItem("company");
    isAuthenticated.value = false;
    Object.assign(user, {});
    Object.assign(company, {});
  };

  return {
    email,
    password,
    user,
    company,
    fieldErrors,
    isSubmitting,
    loginUser,
    updateUser, // Exportar el método de actualización del usuario
    updateCompany, // Exportar el método de actualización de la empresa
    isAuthenticated,
    logoutUser,
    fetchProfileCount,
    profileCount,
  };
});
