<template>


  <!-- =======================
Main banner START -->
<section class="pt-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- Card item START -->
          <div
            class="card overflow-hidden h-100px h-xl-200px rounded-3"
            style="
              background-image: url(/assets/images/bg/fondo-acercade-viaverde.jpg);
              background-position: center left;
              background-size: cover;
            "
          >
            <!-- Background dark overlay -->
            <div class="bg-overlay bg-dark opacity-6"></div>

            <!-- Card image overlay -->
            <div class="card-img-overlay d-flex align-items-start flex-column">
              <!-- Card overlay Top -->
              <!-- <div class="w-100 mb-auto d-flex justify-content-end">
							<button class="btn btn-sm btn-white mb-0"><i class="bi bi-share"></i> Share</button>
						</div> -->
              <!-- Card overlay bottom -->
              <div class="w-100 mt-auto">
                <div class="row p-0 p-sm-3">
                  <div class="col-11 col-lg-7">
                    <!-- Title -->
                    <!-- <h1 class="text-white">¿Preparado para exportar? responde al Diagnóstico</h1>
									<p class="text-white mb-0">Una forma efectiva de ver que capacidades falta desarrollar es respondiendo al Diagnóstico.</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card item END -->
        </div>
      </div>
    </div>
  </section>
  <!-- =======================
Main banner END -->

  <div class="container seccion-1">
    <div class="row">
      <div class="col-md-6 contenido">
        <div class="mb-4 titulo-pagina">Vía <br />Verde</div>

        <div class="subtitulo-pagina mb-4">Exportar con Sostenibilidad</div>

        <p>
          En Bolivia, un país con rica biodiversidad y diversos ecosistemas, ha cultivado una serie de cadenas agroproductivas que desempeñan un papel crucial en su economía. <span class="texto-resaltado-verde">Estas cadenas, además de contribuir significativamente a los ingresos nacionales y la generación de empleo, también han demostrado su capacidad para impulsar el crecimiento económico.</span> En particular, el sector forestal representó un 0,7% de la economía, mientras que el sector agropecuario contribuyó con un 18%, con un récord de exportaciones de más de 3.300 millones de dólares. Estos datos reflejan la importancia estratégica de estos sectores para el desarrollo sostenible de Bolivia. 
        </p>

        

      </div>

      <div class="col-md-6">
        <img
          src="assets/images/img-1.jpg"
          alt="Imagen de un agricultor usando una tablet"
          class="img-fluid img-tablet img-responsive"
        />
      </div>

    </div>
    <div class="row">
      <div class="col-md-12 contenido">
        <p>
          En el contexto actual, <span class="texto-resaltado-verde">el sector agroforestal ha logrado avances notables en la implementación de Buenas Prácticas Agrícolas y también en el Manejo y Aprovechamiento Forestal, un paso fundamental hacia la sostenibilidad.</span> No obstante, queda pendiente la consolidación de estas prácticas en colaboración con las comunidades, con el objetivo de garantizar la gestión sostenible de los recursos forestales y aprovechar las tierras adecuadas para la agricultura. 
          
        </p>
      </div>
    </div>


  </div>
  <!--FIN CONTAINER-->
  <div class="mt-6 mb-6 seccion-3" style="">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          
          <p>
            En este escenario, se plantea el Proyecto “Vía Verde - Exportar con Sostenibilidad” que comprende el desarrollo sostenible para impulsar a las mipymes del sector agroforestal en Santa Cruz, Bolivia y fomentar su internacionalización al mercado de la Unión Europea, el cual busca impulsarlos a través del fortalecimiento de capacidades.
            <br />
            <br />
            Entre los actores involucrados estarán:
<ul>	
  <li>Mipymes agrícolas, pecuarias y forestales</li>
  <li>Mujeres y jóvenes emprendedores entre los 18 a 40 años</li>
  <li>Instituciones gubernamentales y ONGs</li>
  <li>Empresas exportadoras y con potencial exportador</li> 
    <li>Comunidades locales que trabajan en el sector, produzcan y aprovechen productos agroforestales sobre todo en Santa Cruz</li>
    </ul>

          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container seccion-1">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>IMPACTO</h3>

        <h2 class="mb-5 mt-3">
          El impacto esperado por Vía Verde 
        </h2>
      </div>
    </div>
  </div>

  <div class="seccion-2"
    style="background-image: url('assets/images/fondo-1.jpg')"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-4 icon-box">
          <!-- Icon 1 -->
          <img src="assets/images/icon-4.png" width="64" />
          <!-- <h5>Mayor preparación de empresas</h5> -->
          <p>Mayor preparación de empresas para acceder al mercado europeo</p>
        </div>
        <div class="col-md-4 icon-box">
          <!-- Icon 2 -->
          <img src="assets/images/icon-3.png" width="64" />
          <!-- <h5>Empresas que cumplen requisitos</h5> -->
          <p>
            Incremento de empresas que cumplen requisitos para el <br>mercado
            europeo
          </p>
        </div>
        <div class="col-md-4 icon-box">
          <!-- Icon 3 -->
          <img src="assets/images/icon-1.png" width="64" />
          <!-- <h5>Competitividad mejorada</h5> -->
          <p>Mayor competitividad y presencia en mercados internacionales</p>
        </div>
        <div class="col-md-6 icon-box">
          <!-- Icon 4 -->
          <img src="assets/images/icon-2.png" width="64" />
          <!-- <h5>Capacidad productiva mejorada</h5> -->
          <p>
            Mejora general de la capacidad productiva y comercial de <br>las
            empresas
          </p>
        </div>
        <div class="col-md-6 icon-box">
          <!-- Icon 4 -->
          <img src="assets/images/icon-2.png" width="64" />
          <!-- <h5>Capacidad productiva mejorada</h5> -->
          <p>
            Sostenibilidad mejorada y mayor impacto económico en el sector agroforestal
          </p>
        </div>
      </div>
    </div>
  </div>



  <div
    class="seccion-4"
    style="background-image: url('assets/images/fondo3.jpg')"
  >
    <div
      class="container"
      style="
        color: black;
        padding: 30px 15px;
        text-align: justify;
        font-size: 18px;
      "
    >
      <div class="row">
        <div class="col-md-12">
          <h2>Plataforma Inteligente</h2>

         
          <p class="mt-4">
            Para lograr todo ello, se desarrollará una Plataforma inteligente que guiará a las empresas en el proceso de exportación hacia la Unión Europea y diagnosticará a cada organización con criterios de sostenibilidad; también podrán encontrar talleres y capacitaciones sobre producción sostenible; regulaciones y acceso efectivo al mercado europeo; con criterios de Responsabilidad Empresarial (RSE).
<br><br>
            Si desea participar de este Proyecto y conocer más detalles de <span class="texto-resaltado-verde">Vía Verde</span> contáctese aquí:  <a href="mailto:viaverde@ibce.org.bo">viaverde@ibce.org.bo</a> 
          </p>
        </div>
      </div>
    </div>
  </div>
  <FloatingActionButton :categoryNames="categoryNames" />
</template>

<script>
import {ref } from "vue";
import FloatingActionButton from "@/modules/common/components/FloatingActionButton.vue";
export default {
  components: {
    FloatingActionButton,
  },
  setup() {
    //Categoria a mostrar para FAQS
    const categoryNames = ref(["Sobre Vía Verde"]);

    return {
      categoryNames,
    };
  },
};
</script>

<style scoped>

.seccion-1 {
  margin-top: 2rem !important;
}

.seccion-2 {
  color: #ffffff;
  padding: 40px 0;

  background-size: cover;
    background-position: center;
}
.seccion-2 p {
  font-size: 18px;
  font-weight: 500;
  text-shadow: 1px 1px 5px #000000;
}
.seccion-2 img {
  width: 70px;
}

.seccion-3 {
  background-color: #a6e0ba;
  color: black;
  padding: 70px 0px;
  text-align: justify;
  font-size: 18px;
}
.seccion-4 {
  height: 787px;
}
.seccion-4 h1 {
  font-size: 55px;
}

.seccion-1 h3,
.seccion-4 h3 {
  color: #009644;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 4px;
  font-family: "Montserrat";
}

.img-tablet {
  border-radius: 10px;
  max-width: 500px !important;
  width: 100%;
}
.contenido {
  font-family: "Montserrat";
  font-size: 18px;
  text-align: justify;
}
.contenido .titulo-pagina {
  font-family: "Merriweather";
  font-size: 100px;
  font-weight: 900;
  line-height: 100%;
  color: #00a052;
}

.texto-resaltado-verde {
  color: #009644;
  /* font-weight: bold; */
  font-weight: 600;
}

.contenido .subtitulo-pagina {
  color: #009644;
  font-weight: bold;
}
</style>

<style scoped>
h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}

div,
p {
  font-family: "Montserrat";
}

.icon-box {
  text-align: center;
  padding: 20px;
}
.icon-box img {
  width: 50px;
  margin-bottom: 20px;
}
.icon-box h5 {
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 765px) {
  .seccion-1 {
    margin-top: 4rem !important;
  }

  .seccion-2 {
    /* background-image:none!important; */
    background-color: #3daf58;
    padding: 0px;
    background-size: cover;
    background-position: center;
  }

  .seccion-2 p {
    font-weight: 500;
    text-shadow: none !important;
    font-size: 18px;
  }

  .seccion-4 {
    background-image: none !important;
    height: auto;
  }
  .seccion-4 > div {
    padding: 0px 15px;
  }
}
</style>
