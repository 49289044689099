export default {
    name: "categorias",
      children: [
        {
          path: '',
          name: 'lista-categorias',
          component: () => import('@/modules/course/components/CategoryList.vue'),
        },
        {
          path: ':name',
          name: 'categoria-capacitaciones',
          component: () => import('@/modules/course/components/CourseList.vue'),
        },
      ],
  };