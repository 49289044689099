<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header" style="justify-content:center">
        <h3 class="modal-title mb-1">{{ title }}</h3>
        <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="closeModal">Cerrar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Modal title',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  watch: {
    isVisible(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050; /* Asegúrate de que sea lo suficientemente alto */
}
.modal-content {
  background: white;
  border-radius: 5px;
  overflow: hidden;
  max-width: 60vw;
  width: 100%;
  max-height: 70vh;
  height: 100%;
  z-index: 4;
}
/* Media query para resoluciones de teléfono */
@media (max-width: 767.98px) {
  .modal-content {
    max-width: 85vw;
  }
}

.modal-header,
.modal-footer {
  padding: 1rem;
}
.modal-body {
  padding: 1rem;
  overflow-y: scroll;
}
.btn-close {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
