<template>

<!--
================================
MENU DEL PERFIL DE USUARIO
================================
-->


  <!-- =======================
Page Banner START -->
  <section class="pt-0">
    <!-- Main banner background image -->
    <div class="container-fluid px-0">
      <div
        class="bg-blue h-100px h-md-200px rounded-0"
        style="
          background: url(/assets/images/pattern/04.png) no-repeat center center;
          background-size: cover;
        "
      ></div>
    </div>
    <div class="container mt-n4">
      <div class="row">
        <!-- Profile banner START -->
        <div class="col-12">
          <div class="card bg-transparent card-body p-0">
            <div class="row d-flex justify-content-between">
              <!-- Avatar -->
              <div class="col-auto mt-4 mt-md-0">
                <div class="avatar avatar-xxl mt-n3">
                  <img
                    class="avatar-img rounded-circle border border-white border-3 shadow"
                    src="/assets/images/avatar/user-login.jpg"
                    alt=""
                  />
                </div>
              </div>
              <!-- Profile info -->
              <div
                class="col d-md-flex justify-content-between align-items-center mt-4"
              >
                <div>
                  <h1 class="my-1 fs-4">{{ user?.name }}</h1>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item h6 fw-light me-3 mb-1 mb-sm-0">
                      <i class="fas fa-building custom-color me-2"></i>
                      Empresa: {{ company?.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Profile banner END -->

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <hr class="d-xl-none" />
          <!-- Advanced filter responsive toggler END -->
        </div>
      </div>
    </div>
  </section>
  <!-- =======================
Page Banner END -->
  <!-- =======================
Page content START -->
  <section class="pt-0">
    <div class="container">
      <div class="row">
        <!-- Left sidebar START -->
        <div class="col-xl-3">
          <!-- Responsive offcanvas body START -->
          <div class=" " tabindex="-1" id="offcanvasSidebar">
            <!-- Offcanvas body -->
            <div class="offcanvas-body p-3 p-xl-0" style="max-width: 70vw">
              <div class="bg-dark border rounded-3 pb-0 p-3 w-100">
                <!-- Dashboard menu -->
                <div
                  class="list-group list-group-dark list-group-borderless"
                  style="cursor: pointer"
                >
                  <a
                    class="list-group-item"
                    :class="{ active: $route.name === 'profile-diagnostics' }"
                    @click="goToProfileDiagnostics"
                    ><i class="fas fa-book-reader fa-fw me-2"></i
                    >Autodiagnósticos realizados</a
                  >
                  <a
                    class="list-group-item"
                    :class="{ active: $route.name === 'profile-enrollments' }"
                    @click="goToProfileEnrollments"
                    ><i class="fas fa-user-graduate fa-fw me-2"></i
                    >Capacitaciones inscritas</a
                  >
                  <a
                    class="list-group-item"
                    :class="{ active: $route.name === 'showProfile' }"
                    @click="goToProfile"
                    ><i class="fas fa-user fa-fw me-2"></i>Información de
                    perfil</a
                  >
                  <a
                    class="list-group-item text-danger bg-danger-soft-hover"
                    @click.prevent="logout"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-sign-out-alt fa-fw me-2"></i>
                    Cerrar sesión
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Responsive offcanvas body END -->
        </div>
        <!-- Left sidebar END -->
        <router-view></router-view>
      </div>
      <!-- Row END -->
    </div>
  </section>
  <!-- =======================
Page content END -->
</template>

<script>
import { useLoginStore } from "@/modules/auth/stores/login";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const loginStore = useLoginStore();

    // Acceder a la información del usuario
    const user = loginStore.user;
    const company = loginStore.company;

    const logout = () => {
      loginStore.logoutUser();
      router.replace({ name: "login" });
    };

    const goToProfileDiagnostics = () => {
      router.push({ name: "profile-diagnostics" });
    };

    const goToProfileEnrollments = () => {
      router.push({ name: "profile-enrollments" });
    };

    const goToProfile = () => {
      router.push({ name: "showProfile" });
    };

    return {
      logout,
      user,
      company,
      goToProfileDiagnostics,
      goToProfile,
      goToProfileEnrollments,
    };
  },
};
</script>

<style scoped>

h1 {
  font-family: "Merriweather" !important;
  font-weight: 900;
  font-size: 2.3rem;
}
h2 {
  font-family: "Merriweather" !important;
  font-weight: 600;
}
h3,h4,h5{
  font-family: "Merriweather" !important;
}
p,span,li,a,input,label,button{
  font-family: "Montserrat" !important;
}
p,a,span,li{
  font-size: 18px;
}
h2{
  font-size: 2.0rem;
}
h3{
  font-size: 1.5rem;
}
h4{
  font-size: 1.2rem;
}

.list-group-dark .list-group-item {
  color: var(--bs-white);
  margin-bottom: 10px;
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
  border-radius: 0.325rem;
}
.list-group-dark a{
   font-size: 16px;
}


.custom-color {
  color: #24292d;
}
</style>
