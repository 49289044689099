import axios from "axios";
import { useToast } from "vue-toastification";
import router from "@/router";

// Crear una instancia de axios
const apiClient = axios.create({
  baseURL: "https://viaverde.comex.bo/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Agregar un interceptor a la instancia de axios para incluir el token en cada solicitud si está presente
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth_token");
    const excludePaths = ["/login", "/register"];

    // Excluir las rutas de login y register
    if (token && !excludePaths.some((path) => config.url.endsWith(path))) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const excludedUrls = ["/login", "/register"];

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const toast = useToast();

    // Verificar si la URL está en la lista de URLs excluidas
    if (excludedUrls.includes(error.config.url)) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      toast.error(
        "Su sesión ha expirado, por favor inicie sesión nuevamente.",
        {
          position: "top-center",
        }
      );
      localStorage.removeItem("auth_token");
      localStorage.removeItem("user");
      localStorage.removeItem("company");
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);

export default apiClient;
