import { defineStore } from "pinia";
import apiClient from "@/plugins/axios";

export const useDiagnosticStore = defineStore("diagnostics", {
  state: () => ({
    diagnostics: [],
    profileDiagnostics: [],
    diagnosticDetail: null,
    selectedOptions: {},
    loading: true,
    error: "",
  }),
  actions: {
    async getDiagnosticsWithProfileInfo() {
      try {
        const response = await apiClient.get("/diagnostics");
        this.diagnostics = response.data;
        this.error = "";
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else {
          this.error =
            "Error al obtener la información, verifique su conexión a internet o intente de nuevo más tarde.";
        }
      }
    },
    async getDiagnostics() {
      try {
        const response = await apiClient.get("/diagnostics-list");
        this.diagnostics = response.data;
        this.error = "";
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else {
          this.error =
            "Error al obtener la información, verifique su conexión a internet o intente de nuevo más tarde.";
        }
      }
    },
    async getDiagnosticsByProfile() {
      try {
        const response = await apiClient.get("/profile-diagnostics");
        this.profileDiagnostics = response.data;
        this.error = "";
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else {
          this.error =
            "Error al obtener la información, verifique su conexión a internet o intente de nuevo más tarde.";
        }
      }
    },
    async getDiagnosticDetailsById(id) {
      this.loading = true;
      this.error = "";
      this.diagnosticDetail = null;
      try {
        const response = await apiClient.get(`/diagnostic/${id}`);
        this.diagnosticDetail = response.data;
        this.loading = false;
        this.selectedOptions = {};
        return response.data;
      } catch (error) {
        this.loading = false;
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else {
          this.error =
            "Error al obtener el detalle del Autodiagnóstico, verifique su conexión a internet o intente de nuevo más tarde.";
        }
        return null;
      }
    },
    selectOption(questionId, option) {
      // Si no hay opciones seleccionadas para una pregunta, inicializa como un array vacío.
      if (!this.selectedOptions[questionId]) {
        this.selectedOptions[questionId] = [];
      }
      const selectedOptions = this.selectedOptions[questionId];

      if (selectedOptions.includes(option.id)) {
        //Si la opción ya está seleccionada, se elimina del array.
        this.selectedOptions[questionId] = selectedOptions.filter(
          (o) => o !== option.id
        );
      } else {
        //Busca la pregunta a la cual estoy seleccionando la opcion
        const question = this.diagnosticDetail.diagnostic.questions.find(
          (q) => q.id === questionId
        );
        if (question.type === "simple") {
          //Si es una pregunta simple, se asegura de que solo una opción esté seleccionada
          this.selectedOptions[questionId] = [option.id];
        } else if (question.type === "multiple") {
          //Si la opción seleccionada tiene value = 0, reemplaza cualquier otra opción seleccionada con esta.
          if (option.value === 0) {
            this.selectedOptions[questionId] = [option.id];
          } else {
            //Si la opción seleccionada no tiene value = 0, elimina cualquier opción con value = 0 antes de añadir la nueva opción.
            this.selectedOptions[questionId] = selectedOptions
              .filter((o) => {
                const selectedOption = question.options.find(
                  (opt) => opt.id === o
                );
                return selectedOption.value !== 0;
              })
              .concat(option.id);
          }
        }
      }
    },
    //Devuelve true si la opción está seleccionada para la pregunta específica.
    isOptionSelected(questionId, optionId) {
      return (
        this.selectedOptions[questionId] &&
        this.selectedOptions[questionId].includes(optionId)
      );
    },
    validateResponses() {
      const questions = this.diagnosticDetail.diagnostic.questions;
      for (const question of questions) {
        if (
          !this.selectedOptions[question.id] ||
          this.selectedOptions[question.id].length === 0
        ) {
          return false;
        }
      }
      return true;
    },
    async submitDiagnostic() {
      const responses = Object.keys(this.selectedOptions).map((questionId) => {
        return {
          question_id: parseInt(questionId),
          options: this.selectedOptions[questionId],
        };
      });

      const payload = {
        diagnostic_id: this.diagnosticDetail.diagnostic.id,
        responses: responses,
      };
      try {
        await apiClient.post("/submit-diagnostic", payload);
        this.error = "";
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else {
          this.error =
            "Error al procesar el Autodiagnóstico, por favor intente nuevamente.";
        }
      }
    },
    async downloadGlossary() {
      try {
        const response = await apiClient.get("/download-glossary", {
          responseType: "blob",
        });
        this.error = "";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Glosario de Términos de Sostenibilidad.pdf");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else if (error.response && error.response.status === 404) {
          this.error = "No se pudo encontrar el archivo.";
        } else {
          this.error = "Ha ocurrido un error inesperado.";
        }
      }
    },
    async getProfileDiagnostic(diagnosticId) {
      this.error = "";
      try {
        const response = await apiClient.get(
          `/profile-diagnostic/${diagnosticId}`
        );
        this.profileDiagnostic = response.data;
        return response.data;
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else if (error.response && error.response.status === 404) {
          this.error = "No se encontró el Autodiagnóstico del perfil.";
        } else {
          this.error = "Error al obtener el Autodiagnóstico del perfil.";
        }
        return null;
      }
    },
    async downloadReportByLevel(profileDiagnosticId) {
      try {
        const response = await apiClient.get(
          `/download-report/${profileDiagnosticId}`,
          {
            responseType: "blob",
          }
        );
        this.error = "";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", 'Informe de Autodiagnóstico de Sostenibilidad.pdf');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          this.error =
            "No se pudo conectar al servidor. Por favor, intenta nuevamente más tarde.";
        } else if (error.response && error.response.status === 404) {
          this.error = "No se pudo encontrar el archivo.";
        } else {
          this.error = "Ha ocurrido un error inesperado.";
        }
      }
    },
  },
});
